<script>
export default {
  metaInfo: {
    title: "Tests Execute Api",
  }
};
</script>

<script setup>
import PhpServiceView from "@/components/basic/php-wrappers/PhpServiceView.vue";
import {getURLSearchParam} from "@/js/general";

const nID = getURLSearchParam('n_id');
const nIDKey = getURLSearchParam('n_id_key');

const url = `?f=testing&f2=testsExecuteApi&function=exeuteandShowTestbyTestID&n_id=${nID}&n_id_key=${nIDKey}`;


</script>

<template>
  <PhpServiceView :url="url"/>
</template>

<style scoped>

</style>
